import React from 'react'
import History from '../Histroy/History'
import PublicMsg from '../Public Msg/PublicMsg'
import Service from '../OurService/Service'
import Message from '../ExpMsg/Message'
import MobApp from '../OurService/MobApp'
import Hero2 from '../Hero/Hero2'
import CardFilter from '../TopCards/CardFilter'

function Home() {
  return (
    <>

      <Hero2 />
      <CardFilter />
      <History />
      <PublicMsg />
      <Service />
      <MobApp />
      <Message />
   
    </>
  )
}

export default Home