import React from 'react'
import Form from './Form'
import tp from '../assets/TP (1).png'
function Contact() {
    return (
        <>
            <div className='tp-img'>

                <img src={tp} alt="Contact Problem" />
                <div className="head" >
                    <h1  >Contact Us</h1>

                </div>
            </div>
            <div className="container " >

                <Form />
            </div>


        </>
    )
}

export default Contact