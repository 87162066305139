import React from 'react'

import tp from '../assets/TP (1).png'
function Duplicate() {
    return (
        <>
            <div className='tp-img'>

                <img src={tp} alt="Duplicate Problem" />
                <div className="head" >
                    <h1  >Duplicate License</h1>
                </div>
            </div>
            <div className="container" >

                <div className="duplicate-cont cont-drive">
                    <h3>Requirements for Duplicate License:</h3>
                    <ul>
                        <li>Required for LLD Form.</li>
                        <li>Copy of CNIC.</li>
                        <li>Two Passport size fresh Photo Graphs.</li>
                        <li>Report or A certificate issued by relevant Post Office which shows the expiry date of license.</li>
                        <li>FIR (lost license report)of the Police Station.</li>
                        <li>Paste a Ticket of Mentioned Fee on license Documents.</li>
                    </ul>
                </div>
            </div>


        </>
    )
}

export default Duplicate