import React from 'react'
import tp from '../assets/TP (1).png'
function International() {
    return (
        <>
            <div className='tp-img'>

                <img src={tp} alt="International Problem" />
                <div className="head" >
                    <h1>International License</h1>

                </div>
            </div>
            <div className="container cont-all" >
                <div className="inter-cont cont-drive ful-height">
                    <h3>Requirements for International License:</h3>
                    <ul>
                        <li>Required International Application Form.</li>
                        <li>Copy of CNIC.</li>
                        <li>Two Passport size fresh Photo Graphs.</li>
                        <li> A Copy of Valid Driving License issued.</li>
                        <li>A copy of Pakistani Passport valid for Atleast 6 Months with Visa.</li>
                        <li>Paste a Ticket of Mentioned Fee on license Documents.</li>
                    </ul>
                </div>

            </div>


        </>
    )
}

export default International