import React, { useEffect, useState } from "react";
import Card from "./Card";
import AOS from 'aos';
import 'aos/dist/aos.css';
const DrivineCards = () => {
  useEffect(()=>{
    AOS.init({duration: "1500" , delay : "200"});
}, [])
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  const handleClick = (cardIndex) => {
    setSelectedCardIndex(cardIndex);
  };

  return (<>
    <div className="container" >
    <div className="traffic-main">
      {cards.map((card, index) => (
        <Card
        data-aos={card.aos} 
          key={index}
          title={card.title}
          content={card.content}
          image={card.image}
          link = {card.link}
          btn={card.btn}
          onClick={() => handleClick(index)}
          />
          ))}
    </div>
    </div>
          </>
  );
};

const cards = [
  {
    aos : "flip-left" ,
    title: "LICENSE VERIFICATION",
    content: "Get your verification through your CNIC number.",
    image: require('../assets/Verify.png') ,
    link : '/driving/verfication',
    btn : 'Verfication',
  },
  {
    aos : "flip-left" ,
    title: "LEARNER PERMIT",
    content: "Find Out More About Requirements",
    image: require('../assets/learnerPermit.png') ,
    link : '/driving/learnerPermit',
    btn : 'Get info',
  },
  {
    aos : "flip-left" ,
    title: "PERMANENT LICENSE",
    content: "Learn About Permanent License Criteria",
    image: require('../assets/Permanent License.png') ,
    link : '/driving/permanent',
    btn : 'Get info',
  },
  {
    aos : "flip-left" ,
    title: "INTERNATIONAL LICENSE",
    content: "Get Full Requirements for International License",
    image: require('../assets/International.png') ,
    link : '/driving/international',
    btn : 'Get info',
  },
  {
    aos : "flip-left" ,
    title: "DUPLICATE LICENSE",
    content: "Get Duplicate License Requirements",
    image: require('../assets/Duplicate 2.png') ,
    link : '/driving/duplicate',
    btn : 'Get info',
  },
];

export default DrivineCards;
