function GoogleMap({ mapURL }) {
  return (
      <div id="googleCard" className="map-container">
          <iframe src={mapURL} width="600" height="450" allowFullScreen="" loading="lazy" title="Google Map"></iframe>
      </div>
  );
}


export default GoogleMap;
