import React, { useState } from "react";
import Card from "./Card";

const TrafficCards = () => {
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  const handleClick = (cardIndex) => {
    setSelectedCardIndex(cardIndex);
  };





  
  return (<>
    
    <div className="container">
      <div className="traffic-main">
      {cards.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          content={card.content}
          image={card.image}
          link = {card.link}
          btn = {card.btn}
          onClick={() => handleClick(index)}
          />
          ))}
      </div>
    </div>
          </>
  );
};

const cards = [
  {
    title: "Traffic Management",
    content: "Traffic controlled via City and Sariab Circles.",
    image: require('../assets/icons-top/browser.png') ,
    link : '/traffic/mangement',
    btn : 'Get info',
  },
  {
    title: "Traffic Problems",
    content: "Traffic Police need to address many challenges.",
    image: require('../assets/icons-top/cards.png') ,
    link : '/traffic/problem',
    btn : 'Get info',
  },
  {
    title: "Challan/Fines",
    content: "Traffic Police must address challan/fine challenges.",
    image: require('../assets/icons-top/warning 1.png') ,
    link : '/traffic/challanfine',
    btn : 'Get info',
  },


];

export default TrafficCards;
