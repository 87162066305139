import React from 'react'
import tp from '../assets/TP (1).png'
import icon1 from '../assets/learnerPermit.png'
import icon2  from '../assets/Permanent License.png'
import icon3  from '../assets/International.png'
import icon4  from '../assets/Duplicate 2.png'

function DrivineLiecense() {

    return (
        <>
            <div className='tp-img'>

                <img src={tp} alt="Traffic Problem" />
                <div className="head" >
                    <h1  >Drivine Liecense Problem</h1>

                </div>
            </div>
          
            <div className="cont-drive">

                <div className="row cont-para" style={{display: "flex", justifyContent: "center"}}>

                    <div className="col-lg-8" style={{textAlign: "center"}}>

                        <h4> "Your safety is our h2riority; Drive responsibly, live graciously."</h4>
                    </div>


                </div>
                <div className="row first-para " style={{display: "flex", justifyContent: "center"}}>
                    <div className="col-lg-3">
                        <img src={icon1} alt="IMG driving license" />
                    </div>

                <div className="col-lg-6  drive-text">
                    <h2>Leaner Permit </h2>
                    <p>
                    The age requirements vary based on the type of vehicle. For Motorcycles and Motor Cars, the minimum age is 18 years, while for LTV (Rickshaws, Taxi, Tractor Commercial), it is 21 years, and for HTV, it is 22 years. Alongside the application, include a medical certificate of the applicant to ensure their fitness for driving. Submit an attested copy of the Computerized National Identity Card (CNIC) as part of the documentation. Additionally, adhere to the requirement of affixing a ticket of Rs. 20 onto the learner application. It is essential to carefully review and fulfill these prerequisites to facilitate a smooth processing of the learner permit application, and compliance with specific guidelines from the relevant licensing authority is recommended.
                    </p>
                </div>

                </div>
                <div className="row  second-para" style={{display: "flex", justifyContent: "center"}}>
                 

                <div className="col-lg-6 drive-text">
                    <h2>Permanet License </h2>
                    <p>
                    Firstly, provide the original learner permit, which should have a minimum duration of 6 weeks. Include two recent passport-sized photographs with the application. Affix a ticket displaying the specified fee onto the license documents. Lastly, attach the results of the driving test to complete the application process. It is crucial to carefully follow these instructions to ensure a smooth and successful processing of your driving license application. Be sure to review any additional guidelines provided by the relevant licensing authority for comprehensive compliance.
                    </p>
                </div>
                <div className="col-lg-3">
                        <img src={icon2} alt="IMG driving license" />
                    </div>
                </div>
                <div className="row third-para " style={{display: "flex", justifyContent: "center"}}>
                    <div className="col-lg-3">
                        <img src={icon3} alt="IMG driving license" />
                    </div>

                <div className="col-lg-6 drive-text">
                    <h2>International License </h2>
                    <p>
                    Completed International Application Form, a copy of your Computerized National Identity Card (CNIC), and two recent passport-sized photographs. Attach a copy of your valid driving license issued locally. Additionally, provide a copy of your Pakistani passport, ensuring its validity for at least six months, and include the relevant visa. To finalize the application, affix the ticket or proof of payment for the specified fee onto your license documents. It is advisable to carefully review and comply with any specific guidelines provided by the relevant licensing authority to ensure a smooth processing of your International Driving License application.
                    </p>
                </div>

                </div>
                <div className="row  fourth-para" style={{display: "flex", justifyContent: "center"}}>
               

                <div className="col-lg-6 drive-text">
                    <h2>Duplicate License </h2>
                    <p>
                    To complete the Learner's Driving License (LLD) application, ensure you provide the required documents. Include a copy of your Computerized National Identity Card (CNIC) and two recent passport-sized photographs. Obtain a report or certificate from the relevant post office indicating the expiry date of your existing license. In cases of a lost license, submit a First Information Report (FIR) from the local police station, along with a copy of the FIR in your application. Finally, affix the ticket or proof of payment for the specified fee onto your license documents. It's crucial to meticulously follow these instructions and adhere to any additional guidelines set forth by the local licensing authority for a smooth processing of your Learner's Driving License application.
                    </p>
                </div>
                <div className="col-lg-3">
                        <img src={icon4} alt="IMG driving license" />
                    </div>
                </div>
              
            </div>

        </>
    )
}

export default DrivineLiecense