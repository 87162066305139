import React, { useState ,useEffect} from 'react';
import AOS from 'aos'
import 'aos/dist/aos.css';
const AccordionItem = (
  
  { paragraph,subparagraph,lastparagraph, title , cName}) => {
  useEffect(()=>{
    
    AOS.init({duration: '750' , delay: '10'})
  })
  const [opened, setOpened] = useState(false);

  const toggleAccordion = () => {
    setOpened(!opened);
  };

  return (<>
    <div 
      className={`accordion-item ${opened ? 'accordion-item--opened' : ''}` }
      onClick={toggleAccordion}
      style={{ margin: '10px' }} 
      > 
      <div  >
      <div className="accordion-item__line"  >
        <h3 className="accordion-item__title">{title}</h3>
        <span className="accordion-item__icon" />
      </div>
      <div className="accordion-item__inner" >
        <div className="accordion-item__content" >
          <p className="accordion-item__paragraph" >{paragraph} <br/> <br/>{subparagraph} <br/>{lastparagraph}</p>
        </div>
      </div>
      </div>
    </div>
      </>
  );
}

export default AccordionItem;
