import { FaFacebook, FaTwitter, FaInstagram, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import footerImg from '../assets/animat/footerLogo.png'
import { Link } from "react-router-dom";
function Footer() {
  useEffect(() => {
    AOS.init({ duration: "1500", delay: "200" })
  })
  return (
    <div className="footer">
      <a href="/">
              <div className="left-context">
        <img src={footerImg} alt="" />
      </div>
      </a>

      <div className="center-context">
      <div className="details "  >
          <a href="http://fm.qtp.gob.pk/index.html" className="nav-link px-2 text-body-secondary" target="_blank">FM 88.6</a>
          <a href="/contact" className="nav-link px-2 text-body-secondary" target="_blank">Contact Us</a>
          <a href="/about/Officer" className="nav-link px-2 text-body-secondary" target="_blank">Offices</a>
        </div>
        <div className="social-icons01 foo-flex infofooter"  >
          <a href="#" target="_blank">
            <FaEnvelope /><span >info@qtp.gob.pk</span>
          </a>
          <a className='locater' href="https://maps.app.goo.gl/B6hwXKDPTMTfGSJj8" style={{ padding: '0 10px', borderRight: '1px solid #fff', borderLeft: '1px solid #fff' }} target="_blank">
            <FaMapMarkerAlt /><span>Quetta</span>
          </a>
          <a href="#" target="_blank">
            <BsFillTelephoneFill /> <span>+92-819213316</span>
          </a>
        </div>

       
        <div className="footer02">
          <p>© 2023 Balochistan Traffic Police - Quetta Traffic Police Services</p>

        </div>
      </div>
      <div className="right-context">
      <div className="social-icons02 foo-flex" >
       <Link to={'https://www.facebook.com/Azamsarpara'} target="_blank" className="nav-link"> <FaFacebook style={{fontSize: "24px"}}/></Link>
       <Link to={'https://x.com/CpoQuetta?s=20'} target="_blank"  className="nav-link"> <FaTwitter style={{fontSize: "24px"}}/></Link>
       <Link  className="nav-link"> <FaInstagram  style={{fontSize: "24px"}}/></Link>
       
      
      </div>
      </div>
    </div>
  );
}

export default Footer;
