import React, { useEffect } from 'react';
import officer from '../assets/public_Officer.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import rect from '../assets/animat/rect.png'
import coma from '../assets/animat/Group 396.png'
import recttop from '../assets/animat/react-top.png'
import round from '../assets/animat/side-round.png'
import { useTypewriter } from 'react-simple-typewriter'



function PublicMsg() {
  const [text] = useTypewriter({
    words: ['Muhammad Behram Khan Mandokhel'],
    loop: 0
  })
  useEffect(() => {
    AOS.init({ duration: '1500', delay: '200' });
  }, []);

  return (
    <>
    
    <div className="container ">
      <div className="rectTop">
        <img src={recttop} alt="" />
        <img src={round} alt="" />

      </div>
       <div className="rect-box">
            <img src={rect} alt="" />
          </div>
      


      <div className="publicMsg">


        <div className="row flex-con">

          <div className="col-lg-4">
            <div className="officer-img">
              <img src={officer} alt=""  />
            </div>
          </div>

          <div className="col-lg-8 para-section">
            <div className="para pub-para pub-heading  head-befor" data-aos="zoom-in">

              <div className="coma-box">
                <h1>Public Message</h1>
                <img src={coma} alt="" />
              </div>
              <div className='officer_name' >

                <h2><span>{text}</span></h2>
              </div>
              <div className="par">

                <h5>(PSP SSP Traffic)</h5>
                <p data-aos="zoom-in">
                  I'm a traffic police inspector, and I've seen a lot of really sad things because of accidents on the road. Every year, a bunch of people get hurt or even lose their lives in these accidents, and the worst part is, most of them could have been avoided. So, I'm writing to ask you to please be careful when you're driving. Follow the rules—it's important. First off, don't go too fast. Speeding causes a lot of accidents because you don't have enough time to react if something unexpected happens. Also, don't drink and drive. It messes with your thinking and reactions, making driving dangerous. If you've been drinking, find someone sober to take you home. Avoid distractions too, like texting or eating while driving. Keep your eye s on the road and hands on the wheel. Simple things like using your turn signals and checking before changing lanes help prevent accidents too. And please, be patient and kind to other drivers. Getting angry on the road can lead to accidents. We all need to share the road and look out for each other. By doing these things, you're not just keeping yourself safe, but you're helping make the roads safer for everyone. Thanks a lot for listening and doing your part.
                </p>
              </div>
            </div>
          </div>
           
        </div>
      </div>
    </div>
    </>
  );
}

export default PublicMsg;
