import React, { useState } from 'react';
import './id.css';
import ReCAPTCHA from "react-google-recaptcha";
import logo from '../assets/logo.png'
import { BsFillXCircleFill } from "react-icons/bs";

function CNICVerification() {
  const [cnic, setCnic] = useState('');
  const [error, setError] = useState('');
  const [showLicenseInfo, setShowLicenseInfo] = useState(true);
  const siteKey = '6LeLVM8mAAAAAJu-50LPvcVZR6wJqXfQNVMing94';
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [dataapi, setdataapi] = useState({});

  const handleCNICChange = (e) => {
    let cnicInput = e.target.value.replace(/[^0-9]/g, '');

    if (cnicInput.length > 13) {
      cnicInput = cnicInput.substring(0, 13);
    }

    let formattedCNIC = '';
    for (let i = 0; i < cnicInput.length; i++) {
      if (i === 5 || i === 12) {
        formattedCNIC += '-';
      }
      formattedCNIC += cnicInput[i];
    }
    setCnic(formattedCNIC);
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setdataapi({})
    if (cnic.length == 15 && recaptchaToken) {
      const dtobody = {
        Data: {
          typeid: "3",
          tokenno: recaptchaToken,
          cnic: cnic,
        }
      };
      const apiUrl = 'https://ut.qtp.gob.pk/qtp/api/DLLicenseissue/V2/WebLicenseVerification';
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dtobody),
        }).then((response) => response.json())
          .then((products) => {
            if (products.IsSuccess && products.Data) {
              const data = products.Data
              var base64String = `data:image/jpg;base64,${data.appimage}`
              data.newimage = base64String
              setdataapi(data)
              console.log('55555555555555555555555555555555', data)
              setShowLicenseInfo(false);
            }
            else {
              alert(products.Errors);
            }
          })
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handleRecaptcha = (token) => {
    setRecaptchaToken(token);
  };

  const handleClose = () => {
    setShowLicenseInfo(true);
  };

  return (
    <>
      {showLicenseInfo ? <div className="wrapper" >
        <div className="title">License Verification</div>
        <form onSubmit={handleVerification}>
          <div className="field">
            <input
              type="text"
              required
              value={cnic}
              onChange={handleCNICChange}
              placeholder='Enter CNIC Number '
            />
          </div>
          <ReCAPTCHA className='recaptachdesign' sitekey={siteKey} onChange={handleRecaptcha} />
          <div className="field1">
            <input type="submit" value="Verify" />
          </div>
        </form>

        {error && <p className="error error-message">{error}</p>}
      </div>
        :
        <div className="cl-page">
          <div className="verify-logo li-btn" style={{ position: 'relative' }}>

            <button onClick={handleClose} className="close-button verifyClose"><BsFillXCircleFill /></button>
          </div>
          <div className="first-section">
            <div className="first-section">
              <div className="box-se boxVerify">
                <img style={{ width: '100px' }} src={logo} alt="Logo" />
                <ul className='licenceVerTop'>

                  <li><h6>{dataapi?.firstname}</h6></li>
                  <li><span>License Number</span> : <p>{dataapi?.licenseno}</p></li>

                </ul>
                <div className="second-sectionImage">
                  <img src={dataapi?.newimage} alt="Person" />
                </div>
              </div>
              <div className="third-section">
                <ul className='licenceVer'>
                  <li><span>Father/ Husband Name</span> <p> : &nbsp; {dataapi?.fathername}</p></li>
                  <li><span>CNIC</span> <p> : &nbsp; {dataapi?.cnic}</p></li>
                  <li><span>Branch</span> <p> : &nbsp; {dataapi?.sitestxt}</p></li>
                  <li><span>License Type</span> <p> : &nbsp; {dataapi?.licensetypestxt}</p></li>
                  <li><span>License Category</span> <p> : &nbsp; {dataapi?.licensecatstxt}</p></li>
                  <li><span>Date Issue</span> <p style={{ color: '#4d975c' }}> : &nbsp;{dataapi?.issuedate}</p></li>
                  <li><span>Expiry Date</span> <p style={{ color: '#b73535' }}> : &nbsp; {dataapi?.expirty}</p></li>

                  {dataapi?.licensestatus ?
                    <li><span>License Status</span> <p> : &nbsp; {dataapi?.licensestatus}</p></li>
                    : null
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default CNICVerification;
