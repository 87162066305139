import React from "react";
import './cardtop.css'
const Card = ({ title, content, image, link, btn }) => {
  return (
    
    <div class="hero-card">
      <div class="image">
        <img src={image} alt="Verification Card" />
      </div>
      <div class="heading">
        <h2>{title}</h2>
      </div>

      <div class="on-hover">
        <div class="heading-on-hover">
          <h2>{title}</h2>
        </div>
        <div class="paragraph">
          <p>{content}</p>
        </div>
        <div class="link">
          <a href={link} target="_blank">{btn}</a>
        </div>
      </div>
    </div>
  );
};

export default Card;
