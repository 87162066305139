import React from 'react'
import tp from '../assets/TP (1).png'
import tm from '../assets/Traffic-Management.gif'
function Mangement() {
  return (
    <>
      <div className='tp-img'>

        <img src={tp} alt="Mangement Problem" />
        <div className="head" >
          <h1>Traffic Mangement</h1>
        </div>
      </div>
      <div className="container pa-only" >
        <h3>"For better Traffic Management Quetta is divided into two Circles for better Traffic control/supervision."</h3>
        <img className='traffic-management' src={tm} alt="Traffic Management gif" />
      </div>


    </>
  )
}

export default Mangement