import React from 'react';
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaGoogle,
} from 'react-icons/fa';
import './contact.css';
import icon1 from '../assets/Call.png';
import icon2 from '../assets/Location.png';
import icon3 from '../assets/Email.png';

function Form() {
  return (
    <div className='formpage'>
      <section className="">
        <div className="container cont-item">
          <div className="contactInfo">
            <div>
              <ul className="info">
                <li>
                  <span className="icon-container">
                    <img src={icon2} alt="" />
                  </span>
                  <span>
                    SSP Traffic, Spinny Road Quetta, Balochistan Pakistan.
                  </span>
                </li>
                <li>
                  <span className="icon-container">
                    <img src={icon3} alt="" />
                  </span>
                  <span>info@qtp.gob.pk</span>
                </li>
                <li>
                  <span className="icon-container">
                    <img src={icon1} alt="" />
                  </span>
                  <span>+92-819213316</span>
                </li>
              </ul>
            </div>
            <ul className="sci">
              
              <li>
                <a href="#">
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a href="#">
                  <FaTwitter />
                </a>
              </li>
             
              <li>
                <a href="#">
                  <FaGoogle />
                </a>
              </li>
            </ul>
          </div>
          <div className="contactForm">
            <div className="formBox">
              <div className="inputBox w50">
                <span>First Name *</span>
                <input type="text" required />
              </div>
              <div className="inputBox w50">
                <span>Your Email *</span>
                <input type="email" required />
              </div>
              <div className="inputBox w100">
                <span>Subject</span>
                <input type="text" />
              </div>
              <div className="inputBox w100">
                <span>Write Your Message</span>
                <textarea required></textarea>
              </div>
              <div className="input w100 btn">
                <input type="submit" value="Submit" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Form;
