import React, { useState, useEffect } from 'react';
import vg from '../assets/Layer 0.png';
import tp from '../assets/TP (1).png';
import CardOffice from './CardOffice';
import GoogleMap from './GoogleMap';

function TrafficOfficer() {
    const [activeCardIndex, setActiveCardIndex] = useState(0);
    const [mapURL, setMapURL] = useState("");

    const cardDatas = [
        {
            no: "01",
            title: "SSP Traffic Office",
            mapURL: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3448.156546690834!2d66.99076057418596!3d30.204079110992048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ed2e080b6d6f75f%3A0xeb7f2fed6d0c6ac2!2sS%20S%20P%20Traffic%20Police%20Office!5e0!3m2!1sen!2s!4v1698383031370!5m2!1sen!2s",
        },
        {
            no: "02",
            title: "SP Traffic Sariab Circle Office",
            mapURL: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3449.397701565543!2d66.9961087741842!3d30.16863141267856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ed2dfc1fba1c3cb%3A0xd53b785613f24867!2sSariab%20SP%20Traffic%20Office!5e0!3m2!1sen!2s!4v1698383496479!5m2!1sen!2s",
        },
        {
            no: "03",
            title: "DSP Traffic Sariab",
            mapURL: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3449.397701565543!2d66.9961087741842!3d30.16863141267856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ed2dfc1fba1c3cb%3A0xd53b785613f24867!2sSariab%20SP%20Traffic%20Office!5e0!3m2!1sen!2s!4v1698383496479!5m2!1sen!2s",
        },
        {
            no: "04",
            title: "SP Traffic City  Circle Office",
            mapURL: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13792.62683289816!2d66.97531105541994!3d30.204074499999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ed2e080b6d6f75f%3A0xeb7f2fed6d0c6ac2!2sS%20S%20P%20Traffic%20Police%20Office!5e0!3m2!1sen!2s!4v1698383784629!5m2!1sen!2s",
        },
        {
            no: "05",
            title: "DSP Traffic  City",
            mapURL: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55184.334587888945!2d66.9598385535398!3d30.17939661199547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ed2e080b6d6f75f%3A0xeb7f2fed6d0c6ac2!2sS%20S%20P%20Traffic%20Police%20Office!5e0!3m2!1sen!2s!4v1698384857705!5m2!1sen!2s",
        },
        {
            no: "06",
            title: "Traffic Control  Sairab",
            mapURL: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13792.62683289816!2d66.97531105541994!3d30.204074499999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ed2e080b6d6f75f%3A0xeb7f2fed6d0c6ac2!2sS%20S%20P%20Traffic%20Police%20Office!5e0!3m2!1sen!2s!4v1698383784629!5m2!1sen!2s",
        },
        {
            no: "07",
            title: "Traffic Control City",
            mapURL: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55184.334587888945!2d66.9598385535398!3d30.17939661199547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ed2e080b6d6f75f%3A0xeb7f2fed6d0c6ac2!2sS%20S%20P%20Traffic%20Police%20Office!5e0!3m2!1sen!2s!4v1698384857705!5m2!1sen!2s",
        },
        {
            cName: 'hidden'
        },
    ];

    useEffect(() => {
        setMapURL(cardDatas[activeCardIndex].mapURL);
    }, [activeCardIndex, cardDatas]);

    const handleCardClick = (index) => {
        setActiveCardIndex(index);
        setMapURL(cardDatas[index].mapURL);
    };

    return (
        <>
            <div className='tp-img'>
                <img src={tp} alt="Traffic Problem" />
                <h1>Traffic Police Offices</h1>
            </div>

            <div className='locationsection'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 fl-st">
                            <div className="row">
                                {cardDatas.map((card, index) => (
                                    <div className="col-lg-3 col-md-3  	" key={index}>
                                        <CardOffice
                                            card={card}
                                            onClick={() => handleCardClick(index)}
                                            active={index === activeCardIndex}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="col-lg-1 st-img">

                            <img src={vg} alt="" />

                        </div>

                        <div className="col-lg-4">
                            <div className="box1">
                                <GoogleMap mapURL={mapURL} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TrafficOfficer;
