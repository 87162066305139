import React, { useState } from 'react'
import { BsCaretDownFill ,BsCaretUpFill} from "react-icons/bs";

import './SubDriveStyle.css'
import endorsment from '../../assets/subDrivingLicense/NewLearner.png'
function SubDuplicate() {
    const [isActive, setIsActive] = useState(1);
    const [dulplicate, setDuplicate] = useState(1);
    const HandlerReqBtn = (seenNum) => {
        setDuplicate(seenNum)
        setIsActive(seenNum);

    }





    return (

        <div className="container sub t-mr">
            <div className="subdriving" >
            
                <div className={`subBT driveBtns drivingLiencing `}>
                    <button className={`buttonStyle ${isActive === 1 ? 'activebtns' : ''}`}
                        onClick={() => { HandlerReqBtn(1) }}>New</button>
                         <button className={`buttonStyle ${isActive === 2? 'activebtns' : ''}`}
                        onClick={() => { HandlerReqBtn(2) }}>Duplicate</button>
                         <button className={`buttonStyle ${isActive === 3 ? 'activebtns' : ''}`}
                        onClick={() => { HandlerReqBtn(3) }}>Endorsement</button>
        
                </div>
                <div className="req">


                    {dulplicate === 1 && <NewLicense />}
               
                  
                    {dulplicate === 2 && <DuplicateLearner />}
                    {dulplicate === 3 && <EndorsmentLearner />}

                </div>

            </div>
        </div>



    )
}

export default SubDuplicate




const NewLicense = () => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [imgExpanded, setImgExpanded] = useState(false);
    const toggleImgExpend = () => {
        setImgExpanded(!imgExpanded)
    }

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };
    return (

        <div className="main-content-accord">
        <div className="requirement">
          <h6>Requirement</h6>
          <div className="acording-req" onClick={toggleAccordion}>
            <span>New Learner License </span>
            <span>{isExpanded ?<BsCaretDownFill />: <BsCaretUpFill />}</span>
          </div>
          {isExpanded && (
            <div className='acrodion-list-data'>
              <ul>
                <li>Required for LLD Form.</li>
                <li>Copy of CNIC.</li>
                <li>Two Passport size fresh Photo Graphs.</li>
                <li>
                  Report or A certificate issued by relevant Post Office which
                  shows the expiry date of license.
                </li>
                <li>FIR (lost license report)of the Police Station.</li>
                <li>Paste a Ticket of Mentioned Fee on license Documents.</li>
              </ul>
            </div>
          )}
        </div>
        <div className="process">
          <h6>Process</h6>
          <div className="acording-req" onClick={toggleImgExpend}>
            <span>process </span>
            <span>{imgExpanded ?<BsCaretDownFill />: <BsCaretUpFill />}</span>
          </div>
          {imgExpanded && <img src={endorsment} alt="" />}
        </div>
      </div>
    )
}

const DuplicateLearner = () => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [imgExpanded, setImgExpanded] = useState(false);
    const toggleImgExpend = () => {
        setImgExpanded(!imgExpanded)
    }

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };
    return (

        <div className="main-content-accord">
        <div className="requirement">
          <h6>Requirement</h6>
          <div className="acording-req" onClick={toggleAccordion}>
            <span>Process </span>
            <span>{isExpanded ?<BsCaretDownFill />: <BsCaretUpFill />}</span>
          </div>
          {isExpanded && (
            <div>
              <ul>
                <li>Required for LLD Form.</li>
                <li>Copy of CNIC.</li>
                <li>Two Passport size fresh Photo Graphs.</li>
                <li>
                  Report or A certificate issued by relevant Post Office which
                  shows the expiry date of license.
                </li>
                <li>FIR (lost license report)of the Police Station.</li>
                <li>Paste a Ticket of Mentioned Fee on license Documents.</li>
              </ul>
            </div>
          )}
        </div>
        <div className="process">
          <h6>Process</h6>
          <div className="acording-req" onClick={toggleImgExpend}>
            <span>Process </span>
            <span>{imgExpanded ?<BsCaretDownFill />: <BsCaretUpFill />}</span>
          </div>
          {imgExpanded && <img src={endorsment} alt="" />}
        </div>
      </div>
    )
}
const EndorsmentLearner = () => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [imgExpanded, setImgExpanded] = useState(false);
    const toggleImgExpend = () => {
        setImgExpanded(!imgExpanded)
    }

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };
    return (

        <div className="main-content-accord">
        <div className="requirement">
          <h6>Requirement</h6>
          <div className="acording-req" onClick={toggleAccordion}>
            <span>Process </span>
            <span>{isExpanded ?<BsCaretDownFill />: <BsCaretUpFill />}</span>
          </div>
          {isExpanded && (
            <div>
              <ul>
                <li>1Required for LLD Form.</li>
                <li>Copy of CNIC.</li>
                <li>Two Passport size fresh Photo Graphs.</li>
                <li>
                  Report or A certificate issued by relevant Post Office which
                  shows the expiry date of license.
                </li>
                <li>FIR (lost license report)of the Police Station.</li>
                <li>Paste a Ticket of Mentioned Fee on license Documents.</li>
              </ul>
            </div>
          )}
        </div>
        <div className="process">
          <h6>Process</h6>
          <div className="acording-req" onClick={toggleImgExpend}>
            <span>Process </span>
            <span>{imgExpanded ?<BsCaretDownFill />: <BsCaretUpFill />}</span>
          </div>
          {imgExpanded && <img src={endorsment} alt="" />}
        </div>
      </div>
    )
}