import React, { useState, useEffect } from 'react'
import '../../style/onlineappointment.css'
import tp from '../assets/TP (1).png'
import steps0 from '../assets/onlineappointment/steps0.png'
import steps1 from '../assets/onlineappointment/steps1.png'
import steps2 from '../assets/onlineappointment/steps2.png'
import steps3 from '../assets/onlineappointment/steps3.png'
import steps4 from '../assets/onlineappointment/steps4.png'
import steps5 from '../assets/onlineappointment/steps5.png'
import selecttype0 from '../assets/learnerPermit.png'
import selecttype1 from '../assets/Permanent License.png'
import selecttype2 from '../assets/International.png'
import selecttype3 from '../assets/Duplicate 2.png'
import { FaCalendarAlt, FaRegClock } from "react-icons/fa";
import verLogo from '../assets/logo.png'
import Button from "@mui/material/Button";
import { FaAngleLeft, FaAngleRight, FaCheck, FaUser } from "react-icons/fa";
import Grid from "@mui/material/Grid";
import html2pdf from 'html2pdf.js';
import { useRef } from 'react'
import { FaDownload } from "react-icons/fa6";

function OnlineApponintment() {
    const [currentDate, setCurrentDate] = useState(
        new Date().toISOString().substr(0, 10)
    )
    const pdfRef = useRef();
    const [currentDateformate, setCurrentDateformate] = useState('')
    const apiUrl = 'https://ut.qtp.gob.pk/qtp/api/'
    const [steppers, setSteppers] = useState(['Applicant', 'Branch', 'Time Slot', 'Reservation', 'Appointment', 'Confirmed'])
    const [licenceType, setLicenceType] = useState([])
    const [selectbranch, setSelectBranch] = useState([])
    const [timeslot, setTimeslot] = useState([])
    const [counterseat, setCounterseat] = useState([])
    const stepImages = [steps0, steps1, steps2, steps3, steps4, steps5];
    const selecttypeData = [selecttype0, selecttype1, selecttype2, selecttype3];
    const [nameField, setNameField] = useState(null);
    const [numberField, setnumberField] = useState(null);
    const [cnicField, setCnicField] = useState(null);
    const [timeslotselect, setTimeslotselect] = useState('');
    const [seatreserved, setSeatreserved] = useState('');
    const [typelicence, settypelicence] = useState('');
    const [page, setPage] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [startappointment, setStartappointment] = useState(false);
    const [seletoption, setSeletoption] = useState('');
    const [finaldata, setFinaldata] = useState({});
    const [loading, setloading] = useState(false);

    let nextbtn =
        nameField?.length && numberField?.length > 11 &&
            cnicField?.length > 13
            ? false
            : true;

    useEffect(() => {
        const formatDate = (date) => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        };

        const getNextWorkingDay = (date) => {
            const currentDate = new Date(date);
            const dayOfWeek = currentDate.getDay();
            let daysToAdd = 1;
            if (dayOfWeek === 5) {
                daysToAdd = 3;
            }
            else if (dayOfWeek === 6) {
                daysToAdd = 2;
            }
            const nextWorkingDay = new Date(currentDate);
            nextWorkingDay.setDate(currentDate.getDate() + daysToAdd);
            return nextWorkingDay;
        };
        const today = new Date();
        const defaultDate = getNextWorkingDay(today);
        const formattedDate = defaultDate.toISOString().split('T')[0];
        setCurrentDateformate(formatDate(defaultDate));
        setCurrentDate(formattedDate);

    }, []);

    useEffect(() => {
        GetBranchData();
        GetLicensetype();
    }, []);

    const GetBranchData = async () => {
        try {
            const response = await fetch(`${apiUrl}Booking/V2/Branch`);
            const result = await response.json();
            setSelectBranch(result.Data)
        } catch (error) {

        }
    };

    const GetLicensetype = async () => {
        try {
            const response = await fetch(`${apiUrl}Booking/V2/Licensetype`);
            const result = await response.json();
            setLicenceType(result.Data)
        } catch (error) {

        }
    };

    const GetTimeSlot = async () => {
        try {
            const response = await fetch(`${apiUrl}Booking/V2/TimeSlot/${selectedOption}/${typelicence?.licensetypeid}`);
            const result = await response.json();
            setTimeslot(result.Data)
        } catch (error) {

        }
    };
    const GetCounterData = async () => {
        try {
            const response = await fetch(`${apiUrl}Booking/V2/SeatSlot/${selectedOption}/${typelicence?.licensetypeid}/${timeslotselect?.timeslot}/${currentDate}`);
            const result = await response.json();
            setCounterseat(result.Data)
        } catch (error) {

        }
    };
    const savedata = async () => {
        var postData = {
            appdate: currentDate,
            applicantname: nameField,
            cnic: cnicField,
            contactno: numberField,
            slot: timeslotselect?.timeslot,
            dealingtime: seatreserved?.time,
            licensetypeid: typelicence?.licensetypeid,
            branchid: seletoption?.id,
            counterid: seatreserved?.counterid,
        }
        console.log('data', postData)
        fetch(`${apiUrl}Booking/V2/Register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        })
            .then(response => response.json())
            .then(data => {
                setFinaldata(data.Data)
                setloading(true)
                console.log('Post request successful:', data);
            })
            .catch(error => {
                setloading(true)
                console.error('Error during POST request:', error);
            });
    };



    const handleDateChange = (event) => {
        setCurrentDate(event.target.value);
    };

    const nameFieldCall = (event) => {
        setNameField(event.target.value);
    };
    const numberFieldCall = (event) => {
        let cnicInput = event.target.value.replace(/[^0-9]/g, '');
        if (cnicInput.length > 11) {
            cnicInput = cnicInput.substring(0, 11);
        }
        let formattedCNIC = '';
        for (let i = 0; i < cnicInput.length; i++) {
            if (i === 4) {
                formattedCNIC += '-';
            }
            formattedCNIC += cnicInput[i];
        }
        setnumberField(formattedCNIC);
    };
    const cnicFieldCall = (e) => {
        let cnicInput = e.target.value.replace(/[^0-9]/g, '');
        if (cnicInput.length > 13) {
            cnicInput = cnicInput.substring(0, 13);
        }
        let formattedCNIC = '';
        for (let i = 0; i < cnicInput.length; i++) {
            if (i === 5 || i === 12) {
                formattedCNIC += '-';
            }
            formattedCNIC += cnicInput[i];
        }
        setCnicField(formattedCNIC);
    };
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        setSeletoption(selectbranch.filter((a) => a.id == event.target.value)[0])
    };

    const handleselecttype = (v) => {
        settypelicence(v);
    };

    const clickslots = (v) => {
        setTimeslotselect(v);
    };

    const clicksseats = (v) => {
        if (v.register == false) {
            setSeatreserved(v);
        }
        else {
            setSeatreserved('');

        }
    };

    const canalbtn = () => {
        setStartappointment(false);
        window.location.href = '/onlineappointment';
    };
    const downloadAsPDF = () => {
        const input = pdfRef.current;
        const pdfOptions = {
            margin: 10,
            filename: 'appointment_details.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        html2pdf().from(input).set(pdfOptions).save();
    };
    const formDisplay = () => {
        if (page === 0) {
            return (
                <>
                    <div className='data-name'>
                        <div className="input-design">
                            <h2 className="inputheading">Name</h2>
                            <input

                                id="standard-basic"
                                variant="outlined"
                                className="inputdatatag"
                                value={nameField}
                                placeholder='Enter Your Name'
                                onChange={nameFieldCall}
                            />
                        </div>
                        <div className="input-design">
                            <h2 className="inputheading">Mobile Number</h2>
                            <input

                                id="standard-basic"
                                variant="outlined"
                                className="inputdatatag"
                                value={numberField}
                                placeholder='Enter Your Number'
                                onChange={numberFieldCall}
                            />
                        </div>
                        <div className="input-design">
                            <h2 className="inputheading">CNIC</h2>
                            <input

                                id="standard-basic"
                                variant="outlined"
                                className="inputdatatag"
                                value={cnicField}
                                placeholder='Enter Your CNIC'
                                onChange={cnicFieldCall}
                            />
                        </div>
                    </div>

                </>
            );
        }
        else if (page === 1) {
            if (selectedOption == '' || typelicence == '') {
                nextbtn = true
            }
            else {
                nextbtn = false
            }
            return (
                <>
                    <div className='data-name'>
                        <div className="input-design">
                            <h2 className="inputheading" style={{ textAlign: 'center' }}>Select Driving License Branch</h2>
                            <select id="selectOptions" value={selectedOption} onChange={handleSelectChange}>
                                <option value="">Select...</option>
                                {selectbranch?.length && selectbranch.map((option, index) => (
                                    <option key={index} value={option?.id}>
                                        {option?.branch}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='select-type'>
                            {licenceType && licenceType.length > 0 ? licenceType.map((v, i) => {
                                return (
                                    <div key={i} className='selectType-licence'>
                                        <div onClick={() => handleselecttype(v)}>
                                            <div className='selectType-licence-img' style={{ borderColor: typelicence?.licensetypeid == v?.licensetypeid ? '#3072c2' : '' }}>
                                                <img src={selecttypeData[i]} alt={v} />
                                            </div>
                                            <span>{v?.licensetype}</span>
                                        </div>
                                    </div>
                                )
                            }) : ''
                            }
                        </div>
                    </div>
                </>
            );
        }
        else if (page === 2) {
            if (timeslotselect == '') {
                nextbtn = true
            }
            else {
                nextbtn = false
            }
            return (
                <>
                    <div className='data-name'>
                        <div className='tablemain'>
                            <div className='tablemain-thead'>
                                <ul>
                                    <li>Select</li>
                                    <li>Time Slot</li>
                                    <li>Seats</li>
                                    <li>Available</li>
                                    <li>Booked</li>
                                </ul>
                            </div>
                            <div className='tablemain-tbody'>
                                {timeslot && timeslot.length > 0 ? (
                                    timeslot.map((v, i) => (
                                        <ul style={{ color: timeslotselect?.timeslot == v?.timeslot ? '#3072c2' : '' }} key={i} onClick={() => clickslots(v)}>
                                            <li>
                                                {timeslotselect?.timeslot == v?.timeslot ?
                                                    <span className='checktrue'><FaCheck /></span> : ''
                                                }
                                                <FaRegClock />
                                            </li>
                                            <li>{v?.timeslot}</li>
                                            <li>{v?.totalseats}</li>
                                            <li>{v?.avalaibleseat}</li>
                                            <li>{v?.registerseat}</li>
                                        </ul>
                                    ))
                                ) : (
                                    <div>
                                        <p >No time slots available</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div >
                </>
            );
        }
        else if (page === 3) {
            if (seatreserved == '') {
                nextbtn = true
            }
            else {
                nextbtn = false
            }
            return (
                <>
                    <div className='data-name'>
                        <Grid spacing={2} container justifyContent="center">
                            {counterseat && counterseat.length > 0 ? (
                                counterseat.map((v, i) => (
                                    <Grid item xs={12} sm={6} md={4} lg={2} justifyContent="center" key={i} alignItems="center">
                                        <div className='main-counter'>
                                            <p className='main-counter-name'>{v.counter}</p>
                                            <div className='seatslist'>
                                                {
                                                    v?.Detail.map((a, u) => (
                                                        <div className={a.register == false ? 'fornonbooked' : 'forbooked'} onClick={() => clicksseats(a)}>
                                                            <section className={seatreserved && seatreserved?.seatno == a?.seatno && seatreserved?.counterid == v?.counterid ? 'selectedseats' : 'selectedseatsnotselected'}>

                                                                <span>
                                                                    <FaUser />
                                                                </span>
                                                                <p>{a.time}</p>
                                                            </section>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </Grid>
                                ))
                            ) : (
                                <div>
                                    <p>No seats available</p>
                                </div>
                            )}
                        </Grid>
                    </div >
                </>
            );
        }
        else if (page === 4) {
            nextbtn = false
            return (
                <>
                    <div className='data-name'>
                        <div className='appointmentdtl'>
                            <div className='appointmentdtl-head'>
                                <div className='appointmentdtl-head-p'>Plaese Confirm Appointment Details</div>
                            </div>
                            <div className='appointmentdtl-tbody'>
                                <div className='appointmentdtl-tbody-contnet'>
                                    <span>Name</span>
                                    <p>{nameField}</p>
                                </div>
                                <div className='appointmentdtl-tbody-contnet'>
                                    <span>CNIC</span>
                                    <p>{cnicField}</p>
                                </div>
                                <div className='appointmentdtl-tbody-contnet'>
                                    <span>Booking For</span>
                                    <p>{currentDateformate}</p>
                                </div>
                                <div className='appointmentdtl-tbody-contnet'>
                                    <span>Time Slot</span>
                                    <p>{timeslotselect?.timeslot}</p>
                                </div>
                                <div className='appointmentdtl-tbody-contnet'>
                                    <span>Dealing Time</span>
                                    <p>{seatreserved?.time}</p>
                                </div>
                                <div className='appointmentdtl-tbody-contnet'>
                                    <span>Counter</span>
                                    <p>{seatreserved?.counter}</p>
                                </div>
                                <div className='appointmentdtl-tbody-contnet'>
                                    <span>License Type</span>
                                    <p>{typelicence?.licensetype}</p>
                                </div>
                                <div className='appointmentdtl-tbody-contnet'>
                                    <span>Branch</span>
                                    <p>{seletoption?.branch}</p>
                                </div>
                            </div>
                        </div>
                    </div >

                </>
            );
        }
        else if (page === 5) {
            nextbtn = false
            return (
                <>
                    {
                        loading ?
                            <div style={{ position: 'relative' }}>
                                <button className='downloadbtn' onClick={downloadAsPDF}><FaDownload /><span>Download</span></button>
                                <div className='data-name' ref={pdfRef}>
                                    <div className='appointmentdtl'>
                                        <div className='appointmentdtl-head finaldownload'>
                                            <div className='appointmentdtl-head-p'>Appointment Details</div>
                                        </div>
                                        <div className='appointmentdtl-tbody'>
                                            <div className='finalappointment'>
                                                <p>Appointment No : <span style={{ fontWeight: 400 }}>{finaldata.appno}</span></p>
                                                <p>License Type : <span style={{ fontWeight: 400 }}>{finaldata?.hdr_DLBKlicensetype?.licensetype}</span></p>
                                            </div>
                                            <div className='appointmentdtl-tbody-contnet'>
                                                <span>Name</span>
                                                <p>{finaldata.applicantname}</p>
                                            </div>
                                            <div className='appointmentdtl-tbody-contnet'>
                                                <span>CNIC</span>
                                                <p>{finaldata.cnic}</p>
                                            </div>
                                            <div className='appointmentdtl-tbody-contnet'>
                                                <span>Contact No</span>
                                                <p>{finaldata.contactno}</p>
                                            </div>
                                            <div className='appointmentdtl-tbody-contnet'>
                                                <span>Booking For</span>
                                                <p>{currentDateformate}</p>
                                            </div>
                                            <div className='appointmentdtl-tbody-contnet'>
                                                <span>Time Slot</span>
                                                <p>{finaldata?.slot}</p>
                                            </div>
                                            <div className='appointmentdtl-tbody-contnet'>
                                                <span>Dealing Time</span>
                                                <p>{seatreserved?.time}</p>
                                            </div>
                                            <div className='appointmentdtl-tbody-contnet'>
                                                <span>Counter</span>
                                                <p>{seatreserved?.counter}</p>
                                            </div>

                                            <div className='appointmentdtl-tbody-contnet'>
                                                <span>Branch</span>
                                                <p>{finaldata?.hdr_DLBKbranch?.branch}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div>
                            : <div className='loadingstart'>
                                <img src={verLogo} alt="ver logo" />
                            </div>
                    }

                </>
            );
        }
    }

    const handleSave = () => {
        if (page === 1) {
            GetTimeSlot()
        }
        if (page === 2) {
            GetCounterData()
        }
        if (page === 4) {
            savedata()

        }
        setPage((currPage) => {
            if (currPage <= 5) {
                return currPage + 1;
            } else {
            }
        });
    };

    return (
        <>
            <div className='tp-img '>
                <img src={tp} alt="Verfication Problem" />
                <div className="head" >
                    <h1>Online Appointment</h1>
                </div>
            </div>
            <section className='appointment'>
                <div className="container" >
                    {
                        startappointment ?
                            <div className='main_appointment'>
                                <div className='appoinment_header'>
                                    <div className='date_appoinment'>
                                        <p>Appointment Date</p>
                                        <input
                                            type='date'
                                            className='inputagfield '
                                            value={currentDateformate}
                                            onChange={handleDateChange}
                                            disabled
                                        />
                                        <span>{currentDateformate}</span>
                                        {/* <FaCalendarAlt style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }} /> */}
                                    </div>
                                    <div className='main_steppers'>
                                        <div className='steppers_sub'>
                                            {steppers?.map((v, i) => {
                                                return (
                                                    <div key={i} className='defaultsteppers'>
                                                        <div className='img-steps' style={{ background: page == i ? '#3072c2' : 'white' }}>
                                                            <img src={stepImages[i]} alt={v} style={{ filter: page == i ? 'none' : '' }} />
                                                        </div>
                                                        <span>{v}</span>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className='mainallPages'>
                                            {formDisplay()}
                                        </div>
                                        <div className="buttonAll">
                                            <Button
                                                className="backbtn"

                                                variant="contained"
                                                style={{ display: page == 0 ? "" : "none" }}
                                                onClick={() => {
                                                    canalbtn();
                                                }}
                                            >
                                                <span>Cancel</span>
                                            </Button>
                                            <Button
                                                className="backbtn"

                                                variant="contained"
                                                style={{ display: page == 0 || page == 5 ? "none" : "" }}
                                                disabled={page == 0}
                                                onClick={() => {
                                                    setPage((currPage) => currPage - 1);
                                                }}
                                            >
                                                <FaAngleLeft />  <span>Back</span>
                                            </Button>
                                            <Button
                                                className="nextbtn"
                                                variant="contained"
                                                disabled={nextbtn}
                                                style={{ display: page == 5 ? "none" : "" }}
                                                onClick={handleSave}
                                            >
                                                <span>Next</span> <FaAngleRight />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="onlinemain">
                                <div className="onlinemain-logo">
                                    <img src={verLogo} alt="ver logo" />
                                </div>
                                <div className="onlinemaincontnet">
                                    <p>Driving License Balochistan</p>
                                </div>
                                <div className="onlinemain-btn">
                                    <div onClick={() => {
                                        setStartappointment(true);
                                    }} style={{ cursor: 'pointer' }}>Online Appointment</div>
                                </div>
                            </div>
                    }
                </div>
            </section>
        </>
    )
}

export default OnlineApponintment
