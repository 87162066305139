export const menuList = [
    {
        title: 'Home',
        link: '/',
    },
    {       
        title: 'About ',  
        link : '/aboutus',
        submenu: [
            {   
                title: 'Offices',
                link  : '/about/officer',
            },
            {
                title: 'Aims',
                link: '/about/aims',
            },
        ],
    },
    {
        title: ' Driving License',
        link: '/driving',
        submenu: [
        
            {
                title: 'License Verification',
                link: '/driving/verfication',
            },
            {
                title: 'Learner Permit',
                link: '/driving/learnerPermit',
            },
            {
                title: 'Permanent LICENSE',
                link: '/driving/permanent',
            },
            {
                title: 'International LICENSE',
                link: '/driving/international',
            },
            {
                title: 'Duplicate LICENSE',
                link: '/driving/duplicate',
            },
            
        ],
    },
    {
        title: ' Traffic',
        link: '/traffic',
        
        submenu: [
            {
                title: 'Traffic Management',
                link: '/traffic/mangement',
            },
            {
                title: 'Traffic Problems',
                link: '/traffic/problem',
            },
            {
                title: 'Challan/fine',
                link: '/traffic/challanfine',
            },
        ],
    },
 
    // {
    //     title: 'Download',
    //     link: '/download',
    // },
    {
        title: 'Contact Us',
        link: '/contact',
    },
];


