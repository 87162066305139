import React from 'react'

import tp from '../assets/TP (1).png'
import AimPage from './AimPage'
function Aims() {
  return (
    <>
    <div className='tp-img'>

        <img src={tp} alt="Traffic Problem"  />
        <div className="head" >
        <h1>Aims</h1>

        </div>
    </div>
    <div className="container" >

        <AimPage/>
    </div>

  
    </>
    )
}

export default Aims