import React from 'react'
import tp from '../assets/TP (1).png'
import SubDuplicate from './SubDriving/SubDuplicate'
function Learner() {
    return (
        <>
        <div className="cont-all">
            <div className='tp-img'>

                <img src={tp} alt="Learner Problem" />
                <div className="head" >
                    <h1>Learner Permit</h1>

                </div>
            </div>
            <div className="container" >
                <div className="learner-cont cont-drive ful-height">
                    {/* <h3>Requirements for Learner Permit:</h3>
                    <ul>
                        <li>Motor Cycle/Motor Car 18 Years.</li>
                        <li>LTV(Rickshaws,Taxi,Tractor Commercial)21 Years.</li>
                        <li>HTV 22 year.</li>
                        <li> Medical Certificate of applicant.</li>
                        <li>An Attested copy of CNIC</li>
                        <li>Paste a Ticket of Rs. 20 in Learner Application.</li>
                    </ul> */}
                        <SubDuplicate/>
                </div>
            </div>

            </div>
        </>
    )
}

export default Learner