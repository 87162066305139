import React from 'react';
import word from '../assets/file.png'
import { FaArrowRightToBracket } from "react-icons/fa6";

const DownloadPage = () => {
  const formData = [
    { name: 'Regular License Form', format: 'fa-file-word' },
    { name: 'Duplicate License Form', format: 'fa-file-word' },
    { name: 'Renewal License Form', format: 'fa-file-word' },
    { name: 'Medical License Form', format: 'fa-file-word' },
    { name: 'International License Form', format: 'fa-file-word' },
  ];

  return (
    <div className="cont">
      <table>
        <thead>
          <tr className="head-tr">
            <th className="heading" scope="col">
              File Name
            </th>
            <th className="heading" scope="col">
              File Format
            </th>
            <th className="heading" scope="col">
              Download
            </th>
          </tr>
        </thead>
        <tbody>
          {formData.map((data, index) => (
            <tr key={index}>
              <td data-label="File Name">{data.name}</td>
              <td data-label="File Format" className='file-formate'>
              <img src={word} alt="" />
              </td>
              <td data-label="Download" className='download'> 
              <FaArrowRightToBracket style={{fontSize: '1.6rem', transform : 'rotate(90deg)'}}/>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DownloadPage;
