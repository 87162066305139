import React, { useEffect, useState } from 'react';
import TrafficCards from './TrafficCards';
import DrivineCards from './DrivineCards';
import AOS from 'aos'
import 'aos/dist/aos.css';
import vec from '../assets/vectors/vector-top.png'

function CardFilter() {
  useEffect(() => {
    AOS.init({ duration: "1200", delay: "200" })
  });

  const [showFourCards, setShowFourCards] = useState(false);
  const [activeButton, setActiveButton] = useState('traffic');

  const showFour = () => {
    setShowFourCards(true);
    setActiveButton('drivingLicense');
  };

  const showThree = () => {
    setShowFourCards(false);
    setActiveButton('traffic');
  };

  return (
    <div>
      <div className="card-container main-hi">
        <div className="container ">
        <div className="vector-top">
          <img src={vec} alt="" />
        </div>
          <div className="mt-top">
          
            <div className={`btn ${activeButton === 'traffic' ? 'active' : ''}`}>
              <button onClick={showThree}>DRIVING LICENSE</button>
            </div>
            <div className={`btn ${activeButton === 'drivingLicense' ? 'active' : ''}`}>
              <button onClick={showFour}>TRAFFIC</button>
            </div>
          </div>
        
        </div>
        {showFourCards ? (
          <div data-aos="fade-up">
            <TrafficCards />
          </div>
        ) : (
          <div data-aos="fade-up">

            <DrivineCards />
          </div>
        )}
      </div>
    </div>
  );
}

export default CardFilter;
