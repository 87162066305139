import React from 'react';
import './Testimonial.css';
import coma from '../assets/caretaker/Coma.png'
const Testimonial = ({msgCard}) => {
  return (

    <div className="testimonial">
      <div className="vector">
        <img src={coma} alt="Vector" />
      </div>
      <div className="testimonial-body">
        <p>
          {msgCard.description}
        </p>
      </div>
      <div className="testimonial-footer">
        <img src={msgCard.image} alt="Ali Mardan Khan Domki" />
        <h2>{msgCard.careTaker}</h2>
        <h5>{msgCard.designation}</h5>
      </div>
    </div>
  );
};

export default Testimonial;
