import React from 'react'
import aim1 from "../assets/Public.png"
import aim2 from "../assets/Traffic Laws.png"
import aim3 from "../assets/Traffic.png"
import aim4 from "../assets/Accidents.png"


function AimPage() {
    return (
        <>
            <div className="row" style={{ margin: '25px 0' }}>



                <div className="col-lg-6">

                    <div className="left-aim-card">
                        <div className="rect-card">
                            <div className="aim-img">
                                <img src={aim1} alt="" />
                            </div>
                            <div className="aim-text">
                                <h5>Traffic  </h5>
                                <p>
                                    City traffic flow requires a comprehensive approach from authorities and police
                                </p>
                            </div>
                        </div>
                        <div className="rect-card">
                            <div className="aim-img">
                                <img src={aim2} alt="" />
                            </div>
                            <div className="aim-text">
                                <h5>Public </h5>
                                <p>
                                    Traffic police aim to manage traffic while also serving and facilitating the public
                                </p>
                            </div>
                        </div>

                    </div>
                </div>



                <div className="col-lg-6">

                    <div className="right-aim-card">
                        <div className="rect-card">
                            <div className="aim-img">
                                <img src={aim3} alt="" />
                            </div>
                            <div className="aim-text">
                                <h5>Traffic Laws
                                </h5>
                                <p>
                                    Raising public awareness and enforcing traffic laws are key to promoting road safety and responsible behavior
                                </p>
                            </div>
                        </div>
                        <div className="rect-card">
                            <div className="aim-img">
                                <img src={aim4} alt="" />
                            </div>
                            <div className="aim-text">
                                <h5>Accidents </h5>
                                <p>
                                    A comprehensive strategy is needed to reduce accidents and enhance road safety
                                </p>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default AimPage