import React , {useState, useEffect} from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Navbar from './components/navbar/Navbar'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import './style/nav.css'
import './App.css'
import './style/home.css'
import './style/history.css'
import './style/publicmsg.css'
import './style/card.css'
import './style/footer.css'
import './style/social.css'
import './style/traffic.css'
import './style/accordion.css'
import './style/ServiceCard.css'
import './style/cardOfficer.css'
import './style/responsive.css'
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';
import './style/topBtn.css'
import './style/station.css'
import './style/aim.css'
import "./style/download.css"
import './style/about.css'
import TrafficOfficer from './components/TrafficOfficer/TrafficOfficer';
import Contact from './components/Contact/Contact';
import DrivineLiecense from './components/DrivingLiecence/DrivineLiecense';
import Download from './components/Download/Download';
import Aims from './components/Aims/Aims';
import Duplicate from './components/LiencePages/Duplicate';
import Verfication from './components/LiencePages/Verfication';
import Learner from './components/LiencePages/Learner';
import Perminent from './components/LiencePages/Perminent';
import International from './components/LiencePages/International';
import Problem from './components/TrafficPages/Problem';
import Mangement from './components/TrafficPages/Mangement';
import Challan from './components/TrafficPages/Challan';
import MobApp from './components/OurService/MobApp';
import OnlineApponintment from './components/OnlineAppointment/OnlineAppintment.jsx';
import Preloader from './components/preloader/Preloader.jsx';
import About from './components/AboutUs/About.jsx';
function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadingTime = 3500; 

    const timer = setTimeout(() => {
      setLoading(false);
    }, loadingTime);

    return () => clearTimeout(timer);
  }, []);




  return (
    <BrowserRouter>
   {/* // {loading ? (
      <Preloader/>
    ):( */}
      <>
      <Navbar/>
    
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/main' element={<Home/>}/>
        <Route path='/aboutus' element={<About/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/about' element={<TrafficOfficer/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/drivingLiecense' element={<DrivineLiecense/>}/>
        <Route path='/download' element={<Download/>}/>
       
        <Route path='/about/aims' element={<Aims/>}/>
        <Route path='/about/Officer' element={<TrafficOfficer/>}/>

        <Route path='/driving' element={<Verfication/>}/>
        <Route path='/driving/verfication' element={<Verfication/>}/>
        <Route path='/driving/learnerPermit' element={<Learner/>}/>
        <Route path='/driving/permanent' element={<Perminent/>}/>
        <Route path='/driving/international' element={<International/>}/>
        <Route path='/driving/duplicate' element={<Duplicate/>}/>

        <Route path='/traffic' element={<Mangement/>}/>
        <Route path='/traffic/mangement' element={<Mangement/>}/>
        <Route path='/traffic/problem'element={<Problem/>}/>
        <Route path='/traffic/challanfine' element={<Challan/>}/>

        <Route path='/mobapp' element={<MobApp/>}/>

        <Route path='/onlineappointment' element={<OnlineApponintment/>}/>
      </Routes>
      <Footer/>

      </>
      {/* )} */}
    </BrowserRouter>
    )
}
export default App


