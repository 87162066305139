import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from '../assets/Nav_logo.png';
import { menuList } from './menuList';
import { FaHome } from 'react-icons/fa';

function Submenu({ submenuItems }) {
  return (
    <ul className="submenu">
      {submenuItems.map((submenuItem, index) => (

        <li key={index}>

          <NavLink to={submenuItem.link} >
            {submenuItem.title}</NavLink>

        </li>
      ))}
    </ul>
  );
}

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State to control mobile menu
  const scrollThreshold = 10;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollPosition > scrollThreshold) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const canalbtn = () => {
    window.location.href = '/onlineappointment';
  };

  return (
    <div className={`header ${scrolled ? 'scrolled' : ''} ${mobileMenuOpen ? 'mobile-menu-open' : ''}`}>
      <div className="logo">
        <Link to={'/'}>
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className={mobileMenuOpen ? 'menuIcon toggle' : 'menuIcon'} onClick={toggleMobileMenu}>
        <span className="icon icon-bars"></span>
        <span className="icon icon-bars overlay"></span>
      </div>
      <div className={`links-header ${mobileMenuOpen ? 'show-mobile-menu' : ''}`}>
        <div style={{ display: mobileMenuOpen ? 'block' : 'none' }}>
          <img src={logo} alt="" style={{
            width: '160px',
            margin: '4px 10px'
          }} />
        </div>
        <ul className="nav">
          {menuList.map((menuItem, index) => (
            <li className="nav-item" key={index}>
              <NavLink className="nav-link" to={menuItem.link}>
                {menuItem.title}
              </NavLink>
              {menuItem.submenu && <Submenu submenuItems={menuItem.submenu} ></Submenu>}

            </li>
          ))}

          <div className="onlineappointbtn">
            <a href="http://fm.qtp.gob.pk/index.html" className='onlineappointbtn-link' target="_blank" rel="noopener noreferrer">
              FM 88.6
            </a>
          </div>
          <div className='onlineappointbtn'>
            <NavLink className='onlineappointbtn-link' onClick={() => { canalbtn() }}>Online Appointment</NavLink>
          </div>
        </ul>
      </div>
    </div>
  );
}


export default Navbar;
