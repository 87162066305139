import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"



const Hero2 = () => {
    return (
        <div className="hero">

            <Carousel
                infiniteLoop
                autoPlay
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                interval={13000}
                transitionTime={500}
                emulateTouch

            >
                    <div className="pic-1 hero-img">
                        <div className="main-img  ">
                            <div className="hero-cont">

                                <div className="hero-text">
                                    {/* <h6>your safety</h6> */}
                                    <h4>Safety First</h4>
                                    <h1>Drive with Care,<br/> Life is Precious</h1>
                                    <p>Join hands with Balochistan Traffic Police in promoting road safety for everyone.</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* second image */}

                    <div className="pic-2">

                    <div className="main-img  ">
                            <div className="hero-cont">

                                <div className="hero-text">
                                    <h4> Rules Matter</h4>
                                    <h1>Respect Traffic Laws, Respect Life</h1>
                                    <p>Stay informed about the latest traffic regulations and contribute to safer roads in Balochistan.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* third image */}


                    <div className="pic-3">

                    <div className="main-img  ">
                            <div className="hero-cont">

                                <div className="hero-text">
                                    {/* <h6>your safety</h6> */}
                                    <h4>Together for Change</h4>
                                    <h1>Your Role in Road Safety</h1>
                                    <p>Collaborate with us to create a culture of responsibility and safety on Balochistan's roads.</p>
                                </div>
                            </div>
                        </div>

                    </div>
            </Carousel >
            </div>
    );
};

export default Hero2;