

function CardService({ card , link}) {
    return (
      <a className="card1" href="#">
        <div className="ser-head">
        <div className="ser-img">

          <img src={card.image} alt="" />
        </div>
          <h3>{card.title}</h3>
        </div>
        <p className="small">{card.description}</p>
        <div className="go-corner" href="#">
          <div className="go-arrow">
            →
          </div>
        </div>
      </a>
    );
  }
  export default CardService;
