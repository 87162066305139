import React, { useEffect } from "react";
import CardService from "./CardService";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Service() {
  useEffect(() => {
    AOS.init({ duration: "1500", delay: '200' })
  }, [])
  const cardData = [


    {

      title: 'Digital License',
      link: '/',
      image: require('../assets/DigitalLicense-service.gif'),
      description: 'We use handheld devices with thermal printing for seamless challan recording. Our system features end-to-end tracking for accuracy, efficiency, and transparency, enhancing issuance speed and accountability.',
    },
    {
      title: 'E-Challan/ Fine',
      link: '/',
      image: require('../assets/Mobile-App-ser.gif'),
      description: 'We specialize in computerized driving licenses with cutting-edge security measures like secret codes and advanced laser engraving. Our commitment ensures authenticity and security, offering a reliable solution for modern identification needs.',
    },
    {
      title: 'Mobile App',
      link: '/mobapp',
      image: require('../assets/e-challan-service.gif'),
      description: 'Introducing our mobile app on the Google App Store. Empowering the public with a user-friendly platform to track services. Monitor licenses, challans, and more for a comprehensive and convenient solution.',
    },

  ];

  return (
    <div className="service-img">
      <div className="publicMsg service container">
        <div className="ser-heading ser-head-befor">
          <h1>Our Service</h1>
        </div>
        <div className="row pub-center " >
          {cardData.map((card, index) => (

            <div className="col-12 col-sm-6 col-lg-4 service-cards" data-aos="fade-up" key={card.title}>
              <CardService link={card.link} card={card} />
            </div>
          ))}
        </div>


      </div>
    </div>
  );
}
export default Service;