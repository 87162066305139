import React from 'react';

const CardOffice = ({ card, onClick, active }) => {
  const handleClick = (e) => {
    onClick();
    e.preventDefault();
  };

  return (
    <div className="st-btn">
      <div className={` ${card.cName} ${active ? 'active-card' : ''}`}>
        <a href="googleCard" onClick={handleClick}>
          {card.no}
        </a>
        <h3>{card.title}</h3>
      </div>
    </div>
  );
};

export default CardOffice;
