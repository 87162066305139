import React from 'react'
import '../../style/verify.css'
import tp from '../assets/TP (1).png'
import CNICVerification from './CNICVerification'

function Verfication() {
    return (
        <>
            <div className='tp-img '>

                <img src={tp} alt="Verfication Problem" />
                <div className="head" >
                    <h1  >License Verification</h1>

                </div>
                 </div>
              <div className="container" >
                <CNICVerification />
            
              </div>
         
        </>
    )
}

export default Verfication







