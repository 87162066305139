import React, { useEffect } from 'react'
import './mobApp.css'
import { BsApple, BsAndroid2 } from "react-icons/bs";
import mobVideo from "../assets/animat/Mobile.gif"
import respMobile from '../assets/mobilVideo/responsiveMobile.gif'
import flip from '../assets/mobilVideo/mob-flip.png'
import rect from '../assets/animat/rect.png'
import rect2 from '../assets/vectors/vector.png'
import sticks from '../assets/vectors/sticks.png'

import AOS from 'aos';
import 'aos/dist/aos.css';
function MobApp() {
    useEffect(() => {
        AOS.init({ duration: "1500", delay: "100" });
    }, [])
    return (
        <>
            <div className="container">
                <div className="mobApp">
                    <div className="mob-flip">
                            <img src={rect2} className='secImg' alt="" />

                        <img src={flip} alt="" />
                    </div>

                    <div className="pub-heading mob-head-befor">
                        <h1>Download Our App</h1>
                    </div>
                    <div className="rect-box2">
                    <img src={rect2} className='secImg' alt="" />

               <img src={rect} alt="" />
             </div>
                    <div className="appImg" >
                        <img src={sticks} className='sticks' alt="" />

                        <img src={mobVideo} alt="" className='mob-img-2'/>
                        <img src={respMobile} alt="" className='mob-img-1'/>

                    {/* <video src={respVideo} autoPlay muted loop className='mob-img-1' /> */}


                    </div>
                    <div id="wrap" >
                        <a href="https://play.google.com/store/apps/details?id=com.digicop.tpqp.traffic_police_quetta_public" target='_blank' className="btn-slide" >
                            <span className="circle"><BsAndroid2 style={{ fontSize: '2rem', padding: '0px 0px 2px 0px' }} /></span>
                            <span className="title">Play Store</span>
                            <span className="title-hover">Download</span>
                        </a>
                        <a href="#" className="btn-slide2" >
                            <span className="circle2"><BsApple style={{ fontSize: '2rem', padding: '0px 0px 2px 0px' }} /></span>
                            <span className="title2">App Store</span>
                            <span className="title-hover2">Download </span>
                        </a>
                    </div>
                    <div className="rect-box">
               <img src={rect} alt="" />
             </div>
                </div>
            </div>
        </>


    )
}

export default MobApp