import React from 'react'
import tp from '../assets/TP (1).png'
import DownloadPage from './DownloadPage'
function Download() {
  return (
    <>
    <div className='tp-img'>

        <img src={tp} alt="Traffic Problem"  />
        <div className="head" >
        <h1  >Download</h1>

        </div>
    </div>
    <div className="container" >

    <DownloadPage/>
    </div>

  
    </>
    )
}

export default Download