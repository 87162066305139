import React from 'react'
import Accordion from '../Acordion/Accordion'
import tp from '../assets/TP (1).png'
function Problem() {
  return (
    <>
      <div className='tp-img'>
        <img src={tp} alt="Traffic Problem" />
        <div className="head" >
          <h1>Traffic Problems</h1>
        </div>
      </div>
      <div className="container" >
        <Accordion />
      </div>


    </>
  )
}

export default Problem