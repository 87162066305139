import React from 'react'
import tp from '../assets/TP (1).png'
import './Challan.css';
import challan from '../assets/ChallanFines.gif'
function Challan() {
    return (
        <>
            <div className='tp-img'>

                <img src={tp} alt="Challan Problem" />
                <div className="head" >
                    <h1>Challan/Fines</h1>
                </div>
            </div>
            <div className="container">

            
            <div className="CF pa-only" >
                <div>
                    <img src={challan} alt="Challans/Fines" />
                    <h3>"Challan/Fines are made to retrain voilatiers for Traffic also source of collection of Revenue for the Govt where spent the welfare of General public and development of Country. In the year 2005 new Ticketing System was introduced in Quetta City through an Ord: and so far the system is running quiet sucessfully.there are three challan counter and offices for deposit/collection of papers."</h3>
                </div>
            </div>
            </div>

        </>
    )
}

export default Challan