import React from 'react'
import tp from '../assets/TP (1).png'
function Perminent() {
  return (
    <>
      <div className='tp-img'>

        <img src={tp} alt="Perminent Problem" />
        <div className="head" >
          <h1  >Permanent License</h1>

        </div>
      </div>
      <div className="container" >

        <div className="perment-cont cont-drive ful-height">
          <h3>Requirements for Permanent License:</h3>
          <ul>
            <li>Application Form (A).</li>
            <li>Original Learner Permit of at least 6 weeks.</li>
            <li>Two Passport size fresh Photo Graphs.</li>
            <li> Paste a Ticket of Mentioned Fee on license Documents.</li>
            <li>Attached the Driving test Results.</li>
          </ul>
        </div>
      </div>


    </>
  )
}

export default Perminent