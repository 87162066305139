import React from 'react'
import './about.css'
import firstImg from '../assets/aboutImg.png'
import secondImg from '../assets/aboutImg.png'
import tp from '../assets/TP (1).png'
function About() {
  return (
    <>
    <div className='tp-img'>

    <img src={tp} alt="Challan Problem" />
    <div className="head" >
        <h1>About Us</h1>
    </div>
</div>
    <div className="section">
        <div className="container">
    <div className="main-section">
        <div className="sec-img">
            <img src={firstImg} alt="About SP image" />
        </div>
        <div className="sec-text">
            <h1>Learn To Drive, Drive With<br/> Safety And Control.</h1>
            <p>Learning safe driving involves initial vehicle familiarization and obeying traffic laws. Maintain a safe distance from other vehicles on the road. Always signal your intentions for turns and consistently check mirrors along with blind spots for potential hazards.</p>
            <p>Practicing control behind the wheel demands adherence to safety measures. Familiarity with the vehicle's functions aids in responsible driving. Consistent monitoring of blind spots and mirrors ensures awareness. Remember, adherence to traffic laws is fundamental to safe driving practices.</p>
        </div>
    </div>
    </div>
    <div className="second-section">
        <div className="container">
    <div className="main-section ">
      
        <div className="sec-text second-text">
            <h1>Learn To Drive, Drive With<br/> Safety And Control.</h1>
            <p>Learning safe driving involves initial vehicle familiarization and obeying traffic laws. Maintain a safe distance from other vehicles on the road. Always signal your intentions for turns and consistently check mirrors along with blind spots for potential hazards.</p>
            <p>Practicing control behind the wheel demands adherence to safety measures. Familiarity with the vehicle's functions aids in responsible driving. Consistent monitoring of blind spots and mirrors ensures awareness. Remember, adherence to traffic laws is fundamental to safe driving practices.</p>
        </div>
        <div className="secondImg">
            <img src={secondImg} alt="About SP image" />
        </div>
    </div>
    </div>
    </div>
    

    <div className="container">
    <div className="main-section">
        
        <div className="sec-text th-sect">
            <h1>Our service makes you feel<br/> more protected.</h1>
            <p>With our service, your sense of security amplifies significantly. We prioritize your safety above all else, ensuring a shield of protection around you. Our comprehensive approach guarantees a heightened feeling of security, instilling confidence in your experience. From start to finish, we've crafted every aspect to prioritize your peace of mind. Rest assured, our service goes beyond mere provision—it's a commitment to your protection. We understand the importance of feeling secure; hence, our service leaves no stone unturned in safeguarding you. Trust us to elevate your sense of safety and protection, empowering you at every step. You'll find comfort in our meticulous attention to detail, reinforcing your peace and confidence.</p>
        </div>
        <div className="sec-img">
            <img src={firstImg} alt="About SP image" />
        </div>
    </div>
    </div>
    </div>

    </>
  )
}

export default About